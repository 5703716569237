import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const SiteMeta = ({
  site = 'Serata Hawai\'i',
  title = 'Ulta-Luxe Lamborghini Adventure in Hawai\'i',
  description = 'All-Inclusive, Six-Day Lamborghini Adventure at the Four Seasons Hualalai Crafted by Lamborghini Club America Signature Event Series',
  image = 'https://sdemails.s3.amazonaws.com/hero.jpg',
  socialTitle = 'Serata Hawai\'i',
  socialDescription = '',
  socialUrl = 'https://seratahawaii.com/',
  socialDomain = 'seratahawaii.com',
  children
}) => {

  useEffect(() => {
    document.title = `${title} | ${site}`
    document.querySelector('meta[name="description"]').setAttribute('content', description)
  }, [title, description])

  return (
    <Helmet>
      <title>{title} | {site}</title>
      <meta name="description" content={description} />

      {/* facebook meta */}
      <meta property="og:url" content={socialUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={socialTitle || title} />
      <meta property="og:description" content={socialDescription || description} />
      <meta property="og:image" content={image} />

      {/* twitter meta */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={socialDomain} />
      <meta property="twitter:url" content={socialUrl} />
      <meta name="twitter:title" content={socialTitle || title} />
      <meta name="twitter:description" content={socialDescription || description} />
      <meta name="twitter:image" content={image} />

      {children}
    </Helmet>
  )
}

export default SiteMeta
