import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Form } from 'react-bootstrap'
import { models } from '../utils/models'
import getModels from '../utils/getModels'

const Lambo = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const init = {
    model: user.model,
    year: user.year
  }
  const [ model, setModel ] = useState(init.model)
  const [ year, setYear ] = useState(init.year)
  const years = init.model ? getModels.modelYears(init.model) : []

  const handleModel = (car) => {
    if( car !== model ) {
      setYear('')
      setModel(car)
    }
  }

  useEffect(() => {
    const payload = {model, year}
    const diff = JSON.stringify(init) !== JSON.stringify(payload)
    if(diff) dispatch({ type: 'SET_USER', payload })
  }, [model, year])

  return <Row className="w-75 mt-2 ml-2 d-flex flex-column">
    <Form.Group className="mb-2 d-flex align-items-center">
      <Form.Label htmlFor="lamboModel" as={Col} xs={4}>Model</Form.Label>
      <Form.Control as="select" name="lamboModel" id="lamboModel" value={model} onChange={(e) => handleModel(e.target.value)}>
        <option value=''>Select</option>
        {models.map((item, i) => (
          <option key={i} value={item.model}>
            {item.model}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
    {years.length > 0 &&
      <Form.Group className="mb-2 d-flex align-items-center">
        <Form.Label htmlFor="lamboYear" as={Col} xs={4}>Year</Form.Label>
        <Form.Control  as="select" name="lamboYear" id="lamboYear" value={year} onChange={(e) => setYear(e.target.value)}>
          {years.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    }
  </Row>
}

export default Lambo
