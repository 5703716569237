import { models } from './models'

const modelYears = (model) => {
  let item = models.find(x => x.model === model)

  let now = new Date()
  let end = (item.end === 'present') ? now.getFullYear() : parseInt(item.end)
  let start = parseInt(item.start)
  let years = []

  for(let i=end; i>=start; i--) {
    years.push(i)
  }
  return years
}

const modelEditions = (model) => {
  let item = models.find(x => x.model === model)
  return Object.prototype.hasOwnProperty.call(item, 'editions') ? item.editions : false
}

export default { modelYears, modelEditions }
