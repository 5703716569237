import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Col, Form, OverlayTrigger, Popover, Image } from 'react-bootstrap'
import SiteLayout from './SiteLayout'
import Lambo from './Lambo'
import RSVP from './RSVP'
import InfoBtn from '../assets/btn-info.svg'

const prices = {
  base: 29950,
  deposit: 10000,
  guest: 14950,
  suite: 8000,
  bungalow: 25000,
  transport: 3600
}

function Pricing() {
  const dispatch = useDispatch()
  const [ total, setTotal ] = useState(prices.base)
  const [ installmentPrice, setInstallmentPrice] = useState(prices.base/2)
  const [ group, setGroup ] = useState('')
  const [ plusOne, setPlusOne ] = useState(false)
  const [ upgradeSuite, setUpgradeSuite ] = useState(false)
  const [ upgradeBungalow, setUpgradeBungalow ] = useState(false)
  const [ owner, setOwner ] = useState(true)
  const [ transport, setTransport ] = useState(false)
  const [ payOption, setPayOption ] = useState('installment')
  const moneyFormat = { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }

  const formatMoney = (amount) => {
    const amt = parseInt(`${amount||0}`)
    const formatted = amt.toLocaleString('en-US', moneyFormat)
    return formatted
  }

  const handleRoom = (room) => {
    let suite = upgradeSuite
    let bungalow = upgradeBungalow
    setUpgradeSuite(false)
    setUpgradeBungalow(false)
    if(room === 'bungalow') setUpgradeBungalow(!bungalow)
    if(room === 'suite') setUpgradeSuite(!suite)
  }

  useEffect(() => {
    let calc = prices.base
    calc += plusOne && prices.guest
    calc += upgradeSuite && prices.suite
    setTotal(calc)

    let installment = calc-prices.deposit
    setInstallmentPrice(installment) 

    const payload = {
      total: calc,
      group, plusOne, upgradeSuite, upgradeBungalow, owner, transport, payOption, installment
    }
    dispatch({ type: 'SET_USER', payload })

  }, [plusOne, upgradeSuite, upgradeBungalow, transport, payOption])

  const Header = ({ title, desc }) => <>
    <h3 className="font-weight-bold">{title}</h3>
    <h6>{desc}</h6>
  </>

  const Fields = ({ children }) =>
    <Row className="d-flex flex-column my-3">
      {children}
    </Row>

  return <SiteLayout fluid title="Register for Serata Hawai'i">
    <Row id="pricing-page-hero" className="hero d-flex p-4 align-items-center justify-content-center" />
    <Container className="mb-5">
      <Row className="mt-4">
        <Col className="header-text">
          <h1>Reserve Your Spot</h1>
          <p>As part of the Lamborghini Club America membership community, LCA members have unique access to bucket-list Lamborghini driving tours and all-encompassing luxury Lamborghini exclusive experiences.</p>
        </Col>
      </Row>
      <Row id="pricing-page" className="mt-3 mb-sm-5">
        <Col xs={12} sm={4} className="py-4 pl-lg-5 border-right">
          <Header title="1. Select Event" desc="Available events" />
          <Fields>
            <Form.Check
              type="radio"
              value="GROUP1"
              id="groupA"
              name="week"
              label={<><span className="text-muted ml-2">Serata Hawaii 2021</span></>}
              className="h4 d-flex"
              checked={true}
              onChange={(e) => setGroup(e.target.value)}
            />
            <h6 className="mt-4 ml-4 d-flex">
              {formatMoney(prices.base)} per person
              <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                <Popover className="p-3">This price is for single occupancy, if you would like to bring a guest for double occupancy, select the Additional Guest option under Upgrades.</Popover>
              }>
                <Image src={InfoBtn} fluid width="16" className="ml-2" />
              </OverlayTrigger>
            </h6>
          </Fields>
        </Col>
        <Col xs={12} sm={4} className="pt-4 pb-2 pl-lg-5 border-right">
          <Header title="2. Upgrades" desc="Select your options" />
          <Fields>
            <div>
              <Form.Check
                type="checkbox"
                id="additional-guest"
                label="Additional Guest"
                className="h4"
                checked={plusOne}
                onChange={() => setPlusOne(!plusOne)}
              />
              <small>
                {formatMoney(prices.guest)} for guest
                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                  <Popover className="p-3">Select this upgrade if you would like to bring a guest for double occupancy.</Popover>
                }>
                  <Image src={InfoBtn} fluid width="16" className="ml-2" />
                </OverlayTrigger>
              </small>
            </div>
            <div>
              <small className="d-block text-uppercase mt-3 ml-3 mb-0">Room upgrades</small>
              <Form.Check
                type="checkbox"
                id="suite"
                name="suiteUpgrade"
                label="Oceanfront Suite"
                className="h4 mt-2"
                checked={upgradeSuite}
                onChange={() => handleRoom('suite')}
              />
              <small>
                Starts at $1,600 per night
                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                  <Popover className="p-3">Upgrade to 1,050 sq.ft. spacious 1-bdrm suite offering front-row views of the sparkling Pacific Ocean.</Popover>
                }>
                  <Image src={InfoBtn} fluid width="16" className="ml-2" />
                </OverlayTrigger>
              </small>
            </div>
            <div>
              <Form.Check
                type="checkbox"
                id="bungalow"
                name="suiteUpgrade"
                label="Villa &amp; Specialty Suite"
                className="h4 mt-2"
                checked={upgradeBungalow}
                onChange={() => handleRoom('bungalow')}
              />
              <small>
                Select to inquire
                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                  <Popover className="p-3">Upgrade to a 1, 2, or 3 bdrm with privacy in an expansive stand-alone bungalow. Oceanfront views with direct beach access. There are villa and specialty suite configurations to suit all your desires.</Popover>
                }>
                  <Image src={InfoBtn} fluid width="16" className="ml-2" />
                </OverlayTrigger>
              </small>
            </div>
          </Fields>
        </Col>
        <Col xs={12} sm={4} className="py-4 pl-lg-5">
          <Header title="3. Lamborghini" desc="Vehicle and transport" />
          <Fields>
            <div>
              <Form.Check
                type="checkbox"
                id="lamborghini-own"
                label="I Own a Lamborghini"
                className="h4"
                checked={owner}
                onChange={() => setOwner(!owner)}
              />
              <small className="d-flex">
                Shipping from port included
                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                  <Popover className="p-3">Trip includes roundtrip transportation from the port of San Diego to the Four Seasons Hualali</Popover>
                }>
                  <Image src={InfoBtn} fluid width="16" className="ml-2" />
                </OverlayTrigger>
              </small>
            </div>
            {owner &&
              <Lambo />
            }
            <div>
              <Form.Check
                type="checkbox"
                id="lamborghini-transport"
                label="Door-to-Door Delivery"
                className="h4"
                checked={transport}
                onChange={() => setTransport(!transport)}
              />
              <small className="d-flex">
                Select to inquire
                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                  <Popover className="p-3">Choose this option to request a quote for Door-to-Door Lamborghini transportation. All guests receive basic roundtrip from San Diego port to Hawai&apos;i.</Popover>
                }>
                  <Image src={InfoBtn} fluid width="16" className="ml-2" />
                </OverlayTrigger>
              </small>
            </div>
          </Fields>
        </Col>
      </Row>
      <Row className="p-lg-4">
        <Col sm={6}>
          <Row>
            <Col className="text-left">
              <Header title="4. Payment" desc="Select your payment option" />
              <div className="my-3 d-flex justify-content-start">
                <Form.Check
                  type="radio"
                  value="full"
                  id="payment-full"
                  name="payment-option"
                  label="Pay in Full"
                  className="h4 mr-3"
                  checked={payOption === 'full'}
                  onChange={(e) => setPayOption(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  value="installment"
                  id="payment-installment"
                  name="payment-option"
                  label="2 Installments"
                  className="h4 ml-3"
                  checked={payOption === 'installment'}
                  onChange={(e) => setPayOption(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {(payOption === 'full') ?
              <>
                <Col className="text-left">
                  <h3 className="display-5">
                    {formatMoney(total)}*
                  </h3>
                </Col>
                <div className="m-4 d-flex flex-column pr-sm-5">
                  <h6>*Total Package</h6>
                  <small>Pricing is per person (and includes additional guest if selected) but until package availability is confirmed and payment to LCA team, your spot has not been reserved. Submit your booking enquiry and our team will be in contact shortly.</small>
                </div>
              </>
              :
              <>
                <Col className="d-flex justify-content-start">
                  <h3 className="display-5 text-left">
                    {formatMoney(prices.deposit)}<br/>
                    <small>deposit</small>
                  </h3>
                  <h3 className="display-5 mx-1 mx-md-3">+</h3>
                  <h3 className="display-5 text-left">
                    {formatMoney(installmentPrice)}<br/>
                    <small>balance*</small>
                  </h3>
                </Col>
                <div className="m-4 d-flex flex-column pr-sm-5">
                  <h6 className="d-block">*Interest Free Payments</h6>
                  <small>Choose to pay with a deposit followed by a final payment at set interval. This option is calculated per person (and includes additional guest if selected) but your spot will not be reserved until package availability is confirmed and payment submitted to LCA team. Submit your booking enquiry and our team will be in contact shortly.</small>
                </div>
              </>
            }
          </Row>
        </Col>
        <Col sm={6}>
          <h3><strong>5. Complete Reservation</strong></h3>
          <p>Limited availability. Complete this form to reserve your spot on this extraordinary Lamborghini supercar adventure in paradise.</p>
          <br/>
          <RSVP />
        </Col>
      </Row>
    </Container>
  </SiteLayout>
}

export default Pricing
