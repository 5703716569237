import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form, Button, Spinner } from 'react-bootstrap'

const RSVP = ({ modalForm = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const formRef = useRef()
  const fieldRef = useRef()
  const user = useSelector(state => state.user)
  const initUser = {
    fullName: '',
    email: '',
    phone: ''
  }
  const [ data, setData ] = useState(initUser)
  const [ valid, setValid ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ downloadBrochure, setDownloadBrochure ] = useState(user.downloadBrochure)
  const validFeedback = <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
  const linktoBrochure = 'https://sdemails.s3.amazonaws.com/seratahawaii2021.pdf'

  const handleChange = (e) => {
    setData({...data,
      [e.target.name]: e.target.value
    })
  }

  const api = (path, payload) => {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    let options = {
      method: 'POST',
      headers: headers
    }
    if (payload) options.body = JSON.stringify(payload)
    return fetch(`/api/${path}`, options)
      .then(res => res.json())
      .then(data => data)
  }

  const handleSubmit = async (e) => {
    if(fieldRef.current.value !== '') return false
    setLoading(true)
    e.preventDefault()
    const form = formRef.current
    if(form.checkValidity()) {
      setValid(true)
      const payload = {...user, ...data}
      dispatch({ type: 'SET_USER', payload })
      const res = await api('registerInterest', payload)
      if(res)
        history.push('/success')
      else
        setError(true)
    } else {
      form.classList.add('was-validated')
    }
    setLoading(false)
  }

  const handleEmail = async (e) => {
    if(fieldRef.current.value !== '') return false
    setLoading(true)
    e.preventDefault()
    const form = formRef.current
    if(form.checkValidity()) {
      setValid(true)
      const update = { ...data, downloadBrochure: true }
      setData(update)
      const payload = {...user, ...update}
      dispatch({ type: 'SET_USER', payload })
      const res = await api('downloadBrochure', payload)
      if(res)
        setDownloadBrochure(true)
      else
        setError(true)
    } else {
      form.classList.add('was-validated')
    }
    setLoading(false)
  }

  if(modalForm)
    return downloadBrochure ? 
      <div>
        <h2 className="font-weight-bold">Thank You</h2>
        <h6 className="mt-3">
          Link to download:
          <a href={linktoBrochure} target="_blank" rel="noreferrer" className="text-lowercase pl-2">
            SerataHawaii2021.pdf
          </a>
        </h6>
        <h4 className="font-weight-light mt-3 mb-4">We also sent an email with a link to download the brochure. If you have any issues accessing the pdf, please email: <a href="mailto:joy@lamboclubamerica.com">joy@lamboclubamerica.com</a></h4>
      </div>
      :
      <Form ref={formRef} noValidate validated={valid} onSubmit={handleEmail} className="d-flex flex-column">
        <h2 className="font-weight-bold">Download Full Schedule</h2>
        <h4 className="font-weight-light mt-3 mb-4">See the full itinerary and details of this exclusive adventure when you download the event brochure</h4>
        <Form.Group className="d-flex align-items-center my-2">
          <Form.Label htmlFor="firstName" className="w-25">Full Name</Form.Label>
          <div xs={8} className="w-75">
            <Form.Control required type="text" name="fullName" id="fullName" defaultValue={data.fullName} onChange={(e) => handleChange(e)} />
            {validFeedback}
            <Form.Control.Feedback type="invalid">
              Full Name is required
            </Form.Control.Feedback>
          </div>
        </Form.Group>
        <Form.Group className="d-flex align-items-center my-2">
          <Form.Label htmlFor="firstName" className="w-25">Email</Form.Label>
          <div xs={8} className="w-75">
            <Form.Control required type="email" name="email" id="email" defaultValue={data.email} onChange={(e) => handleChange(e)} />
            {validFeedback}
            <Form.Control.Feedback type="invalid">
              Enter a valid email address
            </Form.Control.Feedback>
          </div>
        </Form.Group>
        <Form.Group className="d-none">
          <Form.Control ref={fieldRef} type="text" name="mobile" id="mobile" />
        </Form.Group>
        {error &&
          <h6 className="text-danger">There was a problem with your submission. Please email us: <a href="mailto:joy@lamboclubamerica.com">joy@lamboclubamerica.com</a></h6>
        }
        <div className="mt-4 ml-auto">
          <button onClick={handleEmail} className="h6 btn btn-outline-dark text-lowercase py-2 px-4">
            {loading && <Spinner animation="border" size="sm" />}
            &darr; SerataHawaii2021.pdf
          </button>
        </div>
      </Form>

  return <Form ref={formRef} noValidate validated={valid} onSubmit={handleSubmit} className="text-left">
    <Form.Group className="d-flex">
      <Form.Label htmlFor="firstName" className="w-25">Full Name</Form.Label>
      <div xs={8} className="w-75">
        <Form.Control required type="text" name="fullName" id="fullName" defaultValue={data.fullName} onChange={(e) => handleChange(e)} />
        {validFeedback}
        <Form.Control.Feedback type="invalid">
          Full Name is required
        </Form.Control.Feedback>
      </div>
    </Form.Group>
    <Form.Group className="d-flex">
      <Form.Label htmlFor="email" className="w-25">Email</Form.Label>
      <div xs={8} className="w-75">
        <Form.Control required type="email" name="email" id="email" defaultValue={data.email} onChange={(e) => handleChange(e)} />
        {validFeedback}
        <Form.Control.Feedback type="invalid">
          Enter a valid email address
        </Form.Control.Feedback>
      </div>
    </Form.Group>
    <Form.Group className="d-flex">
      <Form.Label htmlFor="phone" className="w-25">Phone</Form.Label>
      <div xs={8} className="w-75">
        <Form.Control required type="text" name="phone" id="phone" defaultValue={data.phone} onChange={(e) => handleChange(e)} />
        {validFeedback}
        <Form.Control.Feedback type="invalid">
          Phone number is required
        </Form.Control.Feedback>
      </div>
    </Form.Group>
    <Form.Group className="d-none">
      <Form.Control ref={fieldRef} type="text" name="mobile" id="mobile" />
    </Form.Group>
    {error &&
      <h6 className="text-danger">There was a problem with your submission. Please email us: <a href="mailto:joy@lamboclubamerica.com">joy@lamboclubamerica.com</a></h6>
    }
    <Button onClick={handleSubmit}>
      Submit Registration {loading && <Spinner animation="border" size="sm" />}
    </Button>
  </Form>
}

export default RSVP
