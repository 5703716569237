import { Route, Switch } from 'react-router-dom'
import Pricing from './views/Pricing'
import Success from './views/Success'
import Schedule from './views/Schedule'
import Home from './views/Home'

const App = () =>
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/schedule" component={Schedule} />
    <Route exact path="/pricing" component={Pricing} />
    <Route exact path="/success" component={Success} />
  </Switch>

export default App
