exports.models = [
  { model: 'Aventador', start: '2011', end: 'present',
    editions: ['SVJ', 'SVJ Roadster', 'Coupe', 'Coupe LP740-4', 'Roadster LP750-4', 'S Coupe', 'S Roadster']
  },
  { model: 'Huracán', start: '2014', end: 'present',
    editions: ['EVO 2WD', 'EVO Spyder', 'Spyder 2WD', 'Performanté', 'Performanté Spyder']
  },
  { model: 'Urus', start: '2018', end: 'present' },
  { model: 'Gallardo', start: '2003', end: '2013' },
  { model: 'Murciélago', start: '2001', end: '2010' },
  { model: 'Diablo', start: '1990', end: '2001',
    editions: ['SV', 'VT', 'Diablo']
  },
  { model: 'LM002', start: '1986', end: '1993' },
  { model: 'Jalpa', start: '1981', end: '1988' },
  { model: 'Silhouette', start: '1976', end: '1979' },
  { model: 'Countach', start: '1974', end: '1990' },
  { model: 'Urraco', start: '1973', end: '1979' },
  { model: 'Jarama', start: '1970', end: '1976' },
  { model: 'Espada', start: '1968', end: '1978' },
  { model: 'Islero', start: '1968', end: '1969' },
  { model: 'Miura', start: '1966', end: '1972' },
  { model: '400 GT 2 + 2', start: '1966', end: '1968' },
  { model: '350 GT', start: '1964', end: '1966' },
  { model: 'Sián', start: '2020', end: '2020' },
  { model: 'Centenario', start: '2016', end: '2017' },
  { model: 'Veneno', start: '2013', end: '2014' },
  { model: 'Sesto Elemento', start: '2012', end: '2012' },
  { model: 'Reventón', start: '2008', end: '2010' }
]