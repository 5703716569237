import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Modal, Image } from 'react-bootstrap'
import SiteLayout from './SiteLayout'
import RouteMap from './RouteMap'
import RSVP from './RSVP'

function Schedule() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const [showModal, setShowModal] = useState(!user.closedModal)
  const handleModal = () => setShowModal(true)

  const handleCloseModal = () => {
    const payload = { ...user, closedModal: true }
    dispatch({ type: 'SET_USER', payload })
    setShowModal(false)
  }

  const RouteDetails = ({ title, subtitle, description }) => (
    <div className="ml-lg-4 py-4">
      <h3 className="display-4">{title}</h3>
      <h3>{subtitle}</h3>    
      <h3 className="font-weight-light">{description}</h3>
      <div>
        <button className="text-primary btn btn-link px-0" onClick={handleModal}>
          download full schedule
        </button>
        <span className="mx-3 text-muted">|</span>
        <Link to={'/pricing'} className="btn btn-link px-0">Register</Link>
      </div>
    </div>
  )

  const ScheduleDetails = ({ item, text }) => (
    <div className="d-flex align-items-center mb-2">
      {item &&
        <span className="font-weight-light text-muted mr-4">{item}</span>      
      }
      <h3 className="font-weight-light mb-0">{text}</h3>
    </div>
  )

  const CTARow = () => <Row className="bg-dark text-white pt-4 pb-3">
    <Container>
      <Row className="d-flex align-items-center">
        <Col md={6}>
          <h4 className="font-weight-bold">Download Full Schedule</h4>
          <h4 className="font-weight-light mt-3 mb-4">See the full itinerary and details of this exclusive adventure when you download the event brochure</h4>
        </Col>
        <Col md={6} className="text-left text-md-center">
          <button onClick={handleModal} className="h6 btn btn-outline-light text-lowercase py-2 px-4">
            &darr; SerataHawaii2021.pdf
          </button>
        </Col>          
      </Row>
    </Container>
  </Row>

  return <SiteLayout fluid title="Schedule of Six-Day Lamborghini Adventure in Hawai'i">
    <Row id="itinerary-page-hero" className="hero d-flex p-4 align-items-center justify-content-center" />
    <Container className="mt-4 mb-5">
      <Row>
        <Col className="header-text">
          <h1>Escape to the Extraordinary</h1>
          <p>Once the playground of Hawaii’s greatest king, Kamehameha I, guests begin their luxury journey steeped in history while helicoptering above active lava flow, freefall over one of the most beautiful skydives in the world, restoring mind &amp; body, rejuvenating with head-to-toe spa retreats, and indulging in epicurean delights at celebrated restaurants.</p>
        </Col>
      </Row>
    </Container>
    <Container className="my-5">
      <Row>
        <Col md={6} className="mb-4">
          <h2 className="mb-3 font-weight-bold">Dates</h2>
          <ScheduleDetails text="Dec 5 – 10, 2021" />
          <h6 className="text-small text-muted font-weight-light mt-4 mr-5">Availability limited to small gatherings to ensure quality experiences in intimate group setting.</h6>
          <Link to={'/pricing'} className="font-weight-bold border-bottom">Reserve your spot</Link>
        </Col>
        <Col md={6}>
          <h2 className="mb-3 font-weight-bold">Schedule</h2>
          <ScheduleDetails item="DAY 1" text="Arrival & Welcome" />
          <ScheduleDetails item="DAY 2" text="Drive Day 1 / Wellness Retreat" />
          <ScheduleDetails item="DAY 3" text="Drive Day 2" />
          <ScheduleDetails item="DAY 4" text="Choose Your Adventure" />
          <ScheduleDetails item="DAY 5" text="Drive Day 3" />
          <ScheduleDetails item="DAY 6" text="Farewell & Departure" />
        </Col>
      </Row>
    </Container>
    <CTARow />
    <div style={{ maxWidth: '1600px' }} className="mt-5">
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <Image src="https://sdemails.s3.amazonaws.com/four-seasons-hualalai-spa.jpg" fluid className="w-100 imageFit" />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Four Seasons Resort"
            subtitle="Five Star Oceanview Rooms"
            description="Gloriously revitalized, this oceanfront resort captures the essence of natural tropical paradise. Previously ranked No. 1 on U.S. News & World Report’s annual list, making it officially the best hotel in America."
          />
        </Col>
      </Row>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <Image src="https://sdemails.s3.amazonaws.com/17311239_1890630147885088_7113332976531118667_o.jpg" fluid className="w-100 imageFit" />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Vehicle Transport"
            subtitle="Drive your Lamborghini in Hawai'i"
            description="Enjoy multi-day driving experiences around the entire island of Hawai’i in your own Lamborghini. This all-inclusive package provides shipping of your Lamborghini to and from the event destinations."
          />
        </Col>
      </Row>
      <a name="valentino-balboni"></a>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <Image src="https://sdemails.s3.amazonaws.com/valentino-balboni-lead-driver.jpeg" fluid className="w-100 imageFit" />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Valentino Balboni"
            subtitle="Drive With Lamborghini Legend Valentino Balboni"
            description="Valentino Balboni was Lamborghini's chief test driver for 33 years. For fans of the brand, his name is synonymous with driving 12-cylinder cars nearly 200 mph on public roads. You'll have the pleasure of driving alongside the legend during our multi-day drive. Valentino will lead all the drives as well as attend the entire week activities. "
          />
        </Col>
      </Row>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <RouteMap
            kml="SpiritOfAlohaRoute"
            zoom={10.5}
            center={{ lat: 19.624181, lng: -155.898165 }}
          />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Spirit of Aloha Route"
            subtitle="75 miles"
            description="Be greeted by the spirit of Aloha on The Big Island with a half-day scenic drive south of Kona where landscapes become greener, dry lava fields change into lush green forests and coffee plantations. Enjoy a coffee farm and beach-side, historic site."
          />
        </Col>
      </Row>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <Image src="https://sdemails.s3.amazonaws.com/four-seasons-spa-treatments.jpg" fluid className="w-100 imageFit" />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Wellness Retreat"
            subtitle="Spa Treatments"
            description="Guests are pampered with an indulgent spa massage and facial combo at the spa sanctuary to restore the mind, rejuvenate the body, and refocus energy for upcoming adventures."
          />
        </Col>
      </Row>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <RouteMap
            kml="SeaToSkyRoute"
            zoom={10.1}
            center={{ lat: 19.799697, lng: -155.54 }}
          />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Sea to Sky Ridge Route"
            subtitle="185 miles"
            description="Day two drive route pays homage to the lush, green, magical, and remote northern side of the Big Island. The Lamborghini Bulls start from the Four Seasons Resort and slices across the Big Island from east to west, through moonlike landscape Saddle Road soaring to 6,632 feet between the mountains of Mauna Kea and Mauna Loa."
          />
        </Col>
      </Row>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <Image src="https://sdemails.s3.amazonaws.com/four-seasons-kings-pond.jpg" fluid className="w-100 imageFit" />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Choose Your Adventure"
            subtitle="Island Explorations"
            description="Adventurous guests can take to the skies or explore the seas along one of the most beautiful coastlines in the world. Or take another Lamborghini drive to green or black sand beaches."
          />
        </Col>
      </Row>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <RouteMap
            kml="VolcanicJungleRoute"
            zoom={9.4}
            center={{ lat: 19.399697, lng: -155.438991 }}
          />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Volcanic Jungle Route"
            subtitle="188 miles"
            description="The third Lamborghini drive day immerses guests in a variety of landscapes unlike any other drive; lava desert, black sand beach, cool highlands, active lava flows, lush jungle, and views of soaring mountains & plunging valleys."
          />
        </Col>
      </Row>
      <a name="volcano"></a>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <Image src="https://sdemails.s3.amazonaws.com/volcano-hawaii.jpg" fluid className="w-100 imageFit" />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Active Volcano Eruption"
            subtitle="The Eruption of Kilauea Continues on Hawaiʻi"
            description="Hawaii’s Kilauea volcano eruption is in full swing. Lava is still erupting from multiple vents and filling the lava lake creating spectacular views of stunning lava flows ."
          />
        </Col>
      </Row>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <Image src="https://sdemails.s3.amazonaws.com/KON_1459_original.jpg" fluid className="w-100 imageFit" />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Aerial Adventure"
            subtitle="Volcanoes and Waterfalls"
            description="Take an exclusive helicopter journey around the entire island above one of the most active volcanoes on earth and private waterfalls not accessible by land before arriving at the grand finale dinner extravaganza."
          />
        </Col>
      </Row>
      <a name="shipping"></a>
      <Row><Col><hr /></Col></Row>
      <Row className="my-4 align-items-center">
        <Col lg={8} xl={7}>
          <Image src="https://sdemails.s3.amazonaws.com/serata-hawaii-pasha.jpg" fluid className="w-100 imageFit" />
        </Col>
        <Col lg={4} xl={5}>
          <RouteDetails
            title="Overseas Shipping"
            subtitle="Using the Official Shipping of Automobili Lamborghini"
            description="The dedicated route is a short 5 -7 days on the water back and forth from Hawai'i. Our shipping schedule goes from the port of San Diego and is not affected by the chaos that is happening at the port of Long Beach. Cars will be inspected by the award-winning service department at Lamborghini Newport Beach before departure with Pasha Hawai'i, the official shipping company for new cars ordered from Automobili Lamborghini."
          />
        </Col>
      </Row>
    </div>
    <Container>
      <Row><Col>
        <h4 className="text-center font-weight-light my-5">
          And so much more.
          <a href="javascript:;" className="text-dark border-bottom mx-2" onClick={handleModal}>
            Download full schedule
          </a>
          for details.
        </h4>
      </Col></Row>
      <Row className="my-4"><Col>
        <Image src="https://sdemails.s3.amazonaws.com/serata-hawaii-sponsor-logos.jpg" fluid className="w-100" />
      </Col></Row>
    </Container>
    <CTARow />

    <Modal
      show={showModal}
      onHide={handleCloseModal}
      dialogClassName="modal-dialog-centered"
      fullscreen="true"
      animation="true"
      size="lg"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Row>
        <Col lg={4} className="d-none d-lg-block">
          <Image src="https://sdemails.s3.amazonaws.com/hualalai-hawaii-woman.jpg" fluid className="rounded-left" />
        </Col>
        <Col md={12} lg={8}>
          <Modal.Header closeButton className="border-0 py-0" />
          <Modal.Body className="mr-lg-5">
            <RSVP modalForm={true} />
          </Modal.Body>        
        </Col>
      </Row>
    </Modal>
  </SiteLayout>
}

export default Schedule
