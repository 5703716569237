import { Container, Row, Col, Image } from 'react-bootstrap'
import SiteLayout from './SiteLayout'
import IG from '../assets/ig.svg'
import FB from '../assets/fb.svg'

function Success() {

  return <SiteLayout fluid title="Registration Confirmation">
    <Row id="success-page-hero" className="hero d-flex p-4 align-items-center justify-content-center" />
    <Container className="mt-4 mb-5">
      <Row>
        <Col className="header-text">
          <h1>Registration received!</h1>
          <p>Thank you for your interest in Serata Hawai&apos;i. Please allow 24 hours for a LCA team member to confirm availability. Please be assured that we are hard at work securing your reservation.</p>
          <p>We have sent an email confirmation. A Lamborghini Club America team member will be in contact with you regarding any next steps as soon as possible</p>
        </Col>
      </Row>
      <Row className="my-5"><Col><hr/></Col></Row>
      <Row>
        <Col className="header-text text-center">
          <h4>Follow Lamborghini Club America</h4>
          <div className="d-flex justify-content-center">
            <a href="https://www.instagram.com/lamborghiniclubamerica/" className="m-4">
              <Image src={IG} fluid width="64" />
            </a>
            <a href="https://www.facebook.com/lamborghiniclubamerica/" className="m-4">
              <Image src={FB} fluid width="64" />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </SiteLayout>
}

export default Success
