import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Container, Image, Button } from 'react-bootstrap'
import SiteMeta from './SiteMeta'
import Logo from '../assets/serata-hawaii-logo.svg'
import LCA from '../assets/lca_logo.svg'
import '../App.scss'

const SiteNavLinks = () => <>
  <Link to='/schedule' className="text-dark">
    <h6 className="mr-4 mt-2">SCHEDULE</h6>
  </Link>
  <Link to='/pricing' className="text-dark">
    <h6 className="mr-4 mt-2">PRICING</h6>
  </Link>
  <Link to='/pricing'>
    <Button size="sm" variant="primary">
      Register
    </Button>
  </Link>
</>

const SiteNav = () =>
  <Container fluid className="px-3 py-1 d-sm-flex">
    <Link to="/">
      <Image src={Logo} fluid id="logo" className="my-2" style={{width: '380px'}} />
    </Link>
    <div className="ml-auto d-sm-flex align-items-center d-none">
      <SiteNavLinks />
    </div>
    <div className="w-100 d-flex d-sm-none align-items-center justify-content-center">
      <SiteNavLinks size='xs' />
    </div>
  </Container>

const SiteLayout = ({ children, fluid, title, id = '' }) => {
  const [ metaTitle, setMetaTitle ] = useState(title)

  useEffect(() => {
    setMetaTitle(!title ? 'Serata Hawaii' : title)
  }, [children, title])

  return <HelmetProvider>
    <SiteMeta title={metaTitle} />
    <SiteNav />
    <Container id={id} fluid={fluid && true}>
      {children}
    </Container>
    <div id="footer">
      <Container className="text-center">
        <Image src={LCA} fluid className="mx-auto my-2" style={{width: '260px'}} />
        <p className="mt-4 h6 font-weight-light">&copy; Copyright Lamborghini Club America {new Date().getFullYear()}. All Right Reserved. —<a href="https://lamborghiniclubamerica.com/">Join Today</a></p>
      </Container>
    </div>
  </HelmetProvider>
}

export default SiteLayout
