import { Link } from 'react-router-dom'
import { Container, Row, Col, Image } from 'react-bootstrap'
import SiteLayout from './SiteLayout'
import LCA from '../assets/lca_logo.svg'

function Home() {

  const CTAButtons = () => <div className="mt-4">
    <Link to={'/schedule'} className="btn btn-outline-dark my-2 mr-4">
      Full Schedule
    </Link>
    <Link to={'/pricing'} className="btn btn-primary my-2">Register</Link>
  </div>

  return <SiteLayout id="homepage" fluid title="Ulta-Luxe Lamborghini Adventure in Hawai'i">
    <Row className="hero d-flex p-4 align-items-center justify-content-center">
      <Container className="mt-4 mb-5">
        <Row>
          <Col className="text-white text-center">
            <Image src={LCA} fluid className="mx-auto my-2" style={{width: '160px', filter: 'invert(100%)'}} />
            <h1 className="display-2 font-weight-bold">SERATA HAWAI&apos;I</h1>
            <h2 className="h2 font-weight-bold mt-4 mb-3">The Big Island, Hawai&apos;i</h2>
            <h3>DECEMBER 2021</h3>
          </Col>
        </Row>
      </Container>
    </Row>
    <Row className="align-items-center my-5 px-3 px-md-5 px-lg-0 py-5 mx-auto" style={{maxWidth: '1600px'}}>
      <Col lg={6} xl={5} className="header-text px-xl-5">
        <h1>All-Inclusive Lamborghini Adventure Four Seasons Hualalai</h1>
        <p>Join the Lamborghini Club America (LCA) on a multi-day drive adventure around the largest of Hawaiian islands. Your six-day Serata Hawai&apos;i signature Lamborghini adventure has been crafted to the highest of standards – guests experience picturesque driving roads, breathtaking scenery, an epicurean journey of food and wine, all while staying at the Four Seasons Resort Hualalai Five Star, Five Diamond accommodations.</p>
        <CTAButtons />
      </Col>
      <Col xl={1} className="d-none d-xl-block"></Col>
      <Col lg={6} xl={6} className="mt-5 mt-lg-0 text-center">
        <iframe height="420" style={{width: '100%'}} src="https://www.youtube.com/embed/Bv-svwOo5ow" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Col>
    </Row>
    <Row className="align-items-end mx-auto" style={{maxWidth: '1600px'}}>
      <Col lg={8}>
        <Link to={'/schedule'}>
          <Image src="https://sdemails.s3.amazonaws.com/Velocity-Honolulu-_-Luxury-Car-Dealers-Dining-Shopping-_-Living-1.jpg" fluid className="w-100 imageFit d-none d-lg-block" />
        </Link>
      </Col>
      <Col lg={4}>
        <Link to={'/schedule'}>
          <Image src="https://sdemails.s3.amazonaws.com/17358619_1890630094551760_7144323354227885385_o.jpg" fluid className="w-100 imageFit" />
        </Link>
      </Col>
    </Row>
    <Row className="align-items-center mx-auto my-4" style={{maxWidth: '1600px'}}>
      <Col lg={4}>
        <h2 className="font-weight-bold h1">An Ultra-Luxe Lifestyle Event</h2>
        <p className="text-large mt-4">All elements of the six-day Serata Hawai&apos;i signature event were born from a passion for all things Lamborghini and the never-ending quest for the finer things in life.</p>
        <p className="text-large">Availability limited to ensure quality experiences in intimate group setting.</p>
        <CTAButtons />
      </Col>
      <Col lg={8}>
        <Link to={'/schedule'}>
          <Image src="https://sdemails.s3.amazonaws.com/KON_2077.jpg" fluid className="w-100 imageFit mt-5 mt-lg-0" />
        </Link>
      </Col>
    </Row>
    <Container>
      <Row className="my-5"><Col>
        <Image src="https://sdemails.s3.amazonaws.com/serata-hawaii-sponsor-logos.jpg" fluid className="w-100" />
      </Col></Row>
    </Container>
  </SiteLayout>
}

export default Home
