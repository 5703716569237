import { useEffect, useState } from 'react'
import { GoogleMap, LoadScript, KmlLayer } from '@react-google-maps/api'
import { GoogleMapStyles } from '../styles/GoogleMapStyles'

//const googleMapsApiKey = process.env.GOOGLEMAPS_API_KEY
const googleMapsApiKey = 'AIzaSyAN7Z9wZMUrCb3IsridLHkRtLTLCFlwB1M'
const KML_HOST = 'https://sdemails.s3.amazonaws.com'

const ResponsiveSize = (width) => {
  if(width >= 1600) return { width: '100%', height: '55vh' }
  if(width >= 1200) return { width: '100%', height: '55vh' }
  if(width >= 1024) return { width: '100%', height: '50vh' }
  if(width >= 768) return { width: '100%', height: '45vh' }
  if(width >= 480) return { width: '100%', height: '40vh' }

  return { width: '100%', height: '40vh' }
}

const ResponsiveZoom = (width, zoom) => {
  if(width >= 1600) return zoom
  if(width >= 1200) return zoom*.99
  if(width >= 1024) return zoom*.98
  if(width >= 768) return zoom*.97
  if(width >= 480) return zoom*.88

  return zoom*.88
}

function RouteMap({ kml, center, zoom }) {
  const initWidth = window.innerWidth
  const [mapSize, setMapSize] = useState(ResponsiveSize(initWidth))
  const [mapZoom, setMapZoom] = useState(ResponsiveZoom(initWidth, zoom))

  useEffect(() => {
    window.addEventListener('resize', () => {
      const width = window.innerWidth
      const breakpoint = ResponsiveSize(width)
      const z = ResponsiveZoom(width, mapZoom)
      setMapSize(breakpoint)
      setMapZoom(z)
    })
  }, [])


  return <div style={{borderRadius: '5px', overflow: 'hidden'}}>
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <GoogleMap
        zoom={mapZoom}
        center={center}
        mapContainerStyle={mapSize}
        options={{ styles: GoogleMapStyles, disableDefaultUI: true }}
      >
        <KmlLayer
          url={`${KML_HOST}/${kml}.kml`}
          options={{ preserveViewport: true }}
        />
      </GoogleMap>
    </LoadScript>
  </div>
}

export default RouteMap
