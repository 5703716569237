import { combineReducers } from 'redux'
const initState = {
  group: '',
  fullName: '',
  email: '',
  phone: '',
  model: '',
  year: '',
  total: 0,
  installment: 0,
  owner: false,
  plusOne: false,
  transport: false,
  upgradeSuite: false,
  upgradeBungalow: true,
  payOption: 'installment',
  downloadBrochure: false,
  closedModal: false
}

const cartReducer = (state = initState, action) => {
  switch(action.type) {
  case 'SET_CART':

    return { ...action.payload }
  case 'SET_PROMO':     return { ...state,
    promo: action.payload.promo,
    discountCodeId: action.payload.discountCodeId }
  case 'SET_SESSION':   return { ...state, roomName: action.payload.roomName }
  case 'SET_DISCOUNT':  return { ...state, discount: action.payload.discount }
  case 'SET_TOTAL' :    return { ...state, total: action.payload.total }
  case 'SET_BILLING':   return { ...state, billing: action.payload.billing }
  case 'SET_CUSTOMER':  return { ...state, customer: action.payload.customer }
  case 'RESET_CART':    return initState.cart
  default:              return state
  }
}

const userReducer = (state = initState, action) => {
  const { type, payload } = action

  switch(type) {
  case 'SET_USER': return { ...state, ...payload }
  default: return state
  }
}

const rootReducer = combineReducers({
  cart: cartReducer,
  user: userReducer
})

export default rootReducer
